import * as ACTION_TYPES from './Types';

const initialState = {
    isLoggedIn: false,


}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_SUCCESS:
            localStorage.setItem('authToken', JSON.stringify(action.payload.data.accessToken));
            return {
                ...state,
                isLoggedIn: true,
            }
        case ACTION_TYPES.LOGIN_ERROR:
            return {
                ...state,
                isLoggedIn: false,

            }
        case ACTION_TYPES.AUTHENTICATE_USER:
            return {
                ...state,
                isLoggedIn: true,
            }
        case ACTION_TYPES.LOGOUT:
        case ACTION_TYPES.AUTHENTICATE_FAILED:
            localStorage.removeItem("authToken");
            return {
                ...state,
                isLoggedIn: false,
            }

        default:
            return state;
    }
}