// get list of resources
export const GET_RESOURCES_LIST_REQUEST = "GET_RESOURCES_LIST_REQUEST"
export const GET_RESOURCES_LIST_SUCCESS = "GET_RESOURCES_LIST_SUCCESS"
export const GET_RESOURCES_LIST_ERROR = "GET_RESOURCES_LIST_ERROR"

// get single resource
export const GET_RESOURCE_INFO = "GET_RESOURCE_INFO"
export const GET_RESOURCE_REQUEST = "GET_RESOURCE_REQUEST"
export const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS"
export const GET_RESOURCE_ERROR = "GET_RESOURCE_ERROR"

// get bible link
export const GET_BIBLE_LINK_REQUEST = "GET_BIBLE_LINK_REQUEST"
export const GET_BIBLE_LINK_SUCCESS = "GET_BIBLE_LINK_SUCCESS"
export const GET_BIBLE_LINK_ERROR = "GET_BIBLE_LINK_ERROR"
