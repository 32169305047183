import * as ACTION_TYPES from './Types'

const initialState = {
    resourcesList: null,
    resource: null,
    bibleLink: null,
    start: 0,
    length: 10
}

export const resourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_BIBLE_LINK_SUCCESS:
            return {
                ...state,
                bibleLink: action.payload.data
            }
        case ACTION_TYPES.GET_RESOURCES_LIST_SUCCESS:
            return {
                ...state,
                resourcesList: action.payload.data
            }
        case ACTION_TYPES.GET_RESOURCE_SUCCESS:
            return {
                ...state,
                resource: action.payload.data
            }
        case ACTION_TYPES.GET_BIBLE_LINK_ERROR:
            return {
                ...state,
                bibleLink: ""
            }
        case ACTION_TYPES.GET_RESOURCES_LIST_ERROR:
            return {
                ...state,
                resourcesList: []
            }
        case ACTION_TYPES.GET_RESOURCE_ERROR:
            return {
                ...state,
                resource: null
            }
        case ACTION_TYPES.GET_RESOURCE_INFO:
            console.log("file: Reducer.js ~ line 47 ~ action.payload", action.payload)
            return {
                ...state,
                resource: action.payload
            }
        default:
            return state
    }
}