import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant'

export const authenticate = user => dispatch => {
    if (user) {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user })
    } else {
        return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED })
    }
}

export const checkPassword = data => dispatch => {
    return axios
        .post(`${API_BASE}auth/checkPassword`, data)
        .then((res) => {
            if (res.data.status === 200) {
                axios.defaults.headers.common['Authorization'] = res.data.data.accessToken;
                dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data })
                return res.data
            }
            else {
                dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: res.data })
                throw res.data
            }
        })
        .catch((err) => {
            dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response })
            throw err
        })
}

export const logoutAction = data => dispatch => {
    return axios
        .post(`${API_BASE}api/logout`, data)
        .then((res) => {
            if (res.data.status === 200) {
                return res.data
            }
            else {
                throw res.data
            }
        })
        .catch((err) => {
            throw err
        })
}