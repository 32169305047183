import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="app-footer d-flex flex-wrap">
                <div className="container"></div>
            </footer>
        </>
    )
}
export default Footer;