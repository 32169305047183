import React from 'react'
import Warning from '../svg/Warning';
import Back from '../svg/Back';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../store/Login/Actions';

const Header = ({ tabIndex }) => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const id = JSON.parse(localStorage.getItem('browser_id:1'))
    const { resource } = useSelector(state => state.resources)

    const handleLogout = () => {
        dispatch(logoutAction({ deviceId: id }))
            .then(res => {
                localStorage.removeItem("authToken");
                history.push('/login')
            }).catch(err => {
                localStorage.removeItem("authToken");
                history.push('/login')
            })
    }

    return (
        <>
            <header className="app-header">
                {(location.pathname === '/resources' || tabIndex === 0) ? null :
                    <i className="back-icon" onClick={() => { history.goBack() }}>
                        <Back />
                    </i>
                }
                {tabIndex === 0 ? <span>Read</span> : <span>
                    {location.pathname === '/resources' ? "Resources" :
                        resource?.title.length > 15 ? resource?.title.substring(0, 15) + '...' : resource?.title.substring(0, 15)
                    }
                </span>}
                <i className="warn-icon" onClick={handleLogout}>
                    <Warning />
                </i>
            </header>
        </>
    )
}
export default Header;