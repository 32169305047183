import React from 'react'

const Warning = ({
	className
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="27.254" height="25.389" viewBox="0 0 27.254 25.389">
			<g id="np_alert_428650_000000" transform="translate(-5.001 -8.082)">
				<path id="Path_10353" data-name="Path 10353" d="M31.447,26.748l-9-16a4.512,4.512,0,0,0-3.817-2.663,4.512,4.512,0,0,0-3.818,2.663l-9,16a4.622,4.622,0,0,0-.3,4.689,4.623,4.623,0,0,0,4.235,2.035h17.77a4.621,4.621,0,0,0,4.235-2.035,4.622,4.622,0,0,0-.3-4.689Zm-1.507,3.63a2.7,2.7,0,0,1-2.426,1H9.744a2.7,2.7,0,0,1-2.426-1,2.7,2.7,0,0,1,.318-2.6l9-16c.571-1.014,1.3-1.595,1.992-1.595s1.421.581,1.99,1.595l9,16a2.7,2.7,0,0,1,.321,2.6Z" transform="translate(0)" fill="#222433" />
				<path id="Path_10354" data-name="Path 10354" d="M46.825,67.184a1.38,1.38,0,1,0,1.38,1.379A1.379,1.379,0,0,0,46.825,67.184Z" transform="translate(-28.197 -41.204)" fill="#222433" />
				<path id="Path_10355" data-name="Path 10355" d="M46.825,34.168a1.379,1.379,0,0,0-1.379,1.379c0,.041.008.078.011.117h-.007L46,42.349h0a.824.824,0,0,0,1.643,0h0l.55-6.685h-.007c0-.039.011-.076.011-.117a1.38,1.38,0,0,0-1.38-1.379Z" transform="translate(-28.197 -18.186)" fill="#222433" />
			</g>
		</svg>

	)
}
export default Warning;
