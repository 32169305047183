import React from 'react'

const Back = ({
	className
}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="8.761" height="14.453" viewBox="0 0 8.761 14.453">
			<path id="np_arrow_2335739_000000" d="M23.208,7.219l5.246,5.469-1.842,1.767-6.92-7.236L26.612,0l1.842,1.767Z" transform="translate(-19.692 -0.001)" />
		</svg>
	)
}
export default Back;
