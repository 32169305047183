
import { combineReducers } from "redux"

// reducers
import { resourcesReducer } from './Resource/Reducer'
import { loginReducer } from './Login/Reducer'

export const rootReducer = combineReducers({
    login: loginReducer,
    resources: resourcesReducer
});