import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Route, HashRouter, useHistory } from 'react-router-dom';
import Layout from './components/layout/';
import store from './store';
import './config/AxiosConfig'
import { AUTHENTICATE_FAILED } from './store/Login/Types';
import { authenticate, logoutAction } from './store/Login/Actions';
import checkAuth from './components/Authentication/Auth';
import browserId from 'browser-id'
import { useDispatch } from 'react-redux';

const Loading = () => <div className="pt-3 text-center">Loading...</div>

const ScrollToTop = React.lazy(() => import('./components/common/scroll'));

const Login = React.lazy(() => import('./pages/Login'));

const token = JSON.parse(localStorage.getItem("authToken"));


if (token) {
    // console.log("CALL AUTHEN");
    store.dispatch(authenticate(token));
} else {
    // console.log("CALL AUTHEN2");
    store.dispatch({ type: AUTHENTICATE_FAILED });
}

function App() {
    const dispatch = useDispatch()
    const history = useHistory()
    const id = JSON.parse(localStorage.getItem('browser_id:1'))

    var hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }

    function handleVisibilityChange() {
        if (document[hidden]) {
            // console.log("on another page");
            dispatch(logoutAction({ deviceId: id }))
                .then(res => {
                    localStorage.removeItem("authToken");
                    history.push('/login')
                }).catch(err => {
                    localStorage.removeItem("authToken");
                    // history.push('/login')
                })
        } else {
            if (token) {
                store.dispatch(authenticate(token));
            } else {
                store.dispatch({ type: AUTHENTICATE_FAILED });
            }

            // return <Redirect to='/login' />

        }
    }

    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === "undefined" || hidden === undefined) {
        console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
    } else {
        // Handle page visibility change
        document.addEventListener(visibilityChange, handleVisibilityChange, false);

    }

    useEffect(() => {
        browserId()
    }, [])
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Suspense fallback={Loading()}>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/login" component={props => <Login {...props} />} />
                        <Route path="/" component={checkAuth(Layout)} />
                    </Switch>
                </ScrollToTop>
            </Suspense>
        </BrowserRouter>
    );
}
export default App;