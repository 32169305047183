import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppRoute from './App';
import { Provider } from 'react-redux'
import store from './store';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <Provider store={store}>
        <AppRoute />
    </Provider>, document.getElementById("root"));

serviceWorker.register()
reportWebVitals()