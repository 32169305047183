import React, { useState } from "react";
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import routes from '../../routes';

const Layout = props => {
    const [tabIndex, setTabIndex] = useState(JSON.parse(localStorage.getItem("tab")) || 0);
    return (
        <>
            <Header tabIndex={tabIndex} />
            <main className="app-main">
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} tabIndex={tabIndex} setTabIndex={setTabIndex} />
                                )}
                            />
                        ) : (null)
                    })}
                    <Redirect to="/login" />
                </Switch>
            </main>
            <Footer />
        </>
    )
}

export default Layout;