import React from 'react';

const Resources = React.lazy(() => import('./pages/Resources'));
const ResourcesDetails = React.lazy(() => import('./pages/ResourcesDetails'));

const routes = [
    { path: '/resources-details/:id', exact: true, name: 'ResourcesDetails', component: ResourcesDetails },
    { path: '/resources', exact: true, name: 'Resources', component: Resources },
]

export default routes